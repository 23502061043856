import http from '@/utils/http'
import Qs from 'qs'

// 修改用户名(修改资料)
export function update_user_name(data) {
    return http({
        url: '/u-base/ac-update_user_name',
        method: 'post',
        data: Qs.stringify(data)
    })
}