<template>
  <div id="index">
    <div class="content">
      <div class="header">个人中心</div>
      <div class="info">
        <div class="personal">
          <div class="p-left">
            <img src="@/assets/img/index/people.png" alt="" />
            <p class="text-ellipsis" :title="userInfo.user_name">
              {{ userInfo.user_name }}
            </p>
          </div>
          <div class="p-right">
            <div class="p-r-list">
              <span
                >手机：<font>{{ userInfo.account }}</font></span
              >
            </div>
            <div class="p-r-list">
              <span
                >注册日期：<font>{{ userInfo.reg_time }}</font></span
              >
            </div>
            <div class="p-r-list">
              <router-link to="/box/resetPsd">重置密码</router-link>
              <i></i>
              <a href="javascript:;" @click="dialogShowFn">编辑资料</a>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="company">
          <div class="c-left">
            <img src="@/assets/img/index/company_logo.png" alt="" />
          </div>
          <div class="c-right">
            <div v-if="userInfo.role == '1'" class="company_center" @click="$router.push('/box/companyCenter')">
              企业中心<i class="el-icon-arrow-right"></i>
            </div>
            <div class="c-r-list">
              <h3 class="text-ellipsis" style="max-width: 75%">{{ userInfo.holder_name }}</h3>
            </div>
            <div class="c-r-list">
              <span v-if="$store.state.userInfo.vip_type == 5">
                <span style="color: #333333" >基础会员</span
              ></span>
              <span v-if="$store.state.userInfo.vip_type == 10"
                ><img src="@/assets/img/index/ip.png" alt="" style="width: 19px; height: 19px"/><span
                  style="color: #5fc2ee" >试用会员</span
                ></span
              >
              <span v-if="$store.state.userInfo.vip_type == 20"
              ><img src="@/assets/img/index/vip.png" alt="" /><span
                  style="color: #c49c5c" >标准版会员</span
              ></span
              >
              <span v-if="$store.state.userInfo.vip_type == 30"
              ><img src="@/assets/img/index/svip.png" alt="" /><span
                  style="color: #855cd0;">至尊版会员</span
              ></span
              >
            </div>
            <div class="c-r-list">
              <span>到期时间：{{ userInfo.vip_end_time }}</span>
            </div>
          </div>
        </div>
      </div>
      <div id="tabs">
        <router-link
          :to="el.router"
          class="tabs"
          v-for="(el, index) in tabs"
          :key="index"
        >
          <img class="tabs-logo" :src="el.img" alt="" />
          <span>{{ el.name }}</span>
          <img
            class="tabs-icon"
            src="@/assets/img/index/icon_right.png"
            alt=""
          />
          <img
            class="tabs-icon-hover"
            src="@/assets/img/index/icon_right_hover.png"
            alt=""
          />
        </router-link>
      </div>
      <div class="bg">
        <img src="@/assets/img/index/bg.png" alt="" />
      </div>
    </div>
    <DialogBox
      title="编辑个人资料"
      width="435"
      contentWidth="100%"
      contentMargin="0"
      v-show="dialogShow"
      @closeFn="closeFn"
    >
      <div slot="content" class="dialog-content">
        <div class="dc-list">
          <span>账号名称：</span>
          <div>
            <input
              :class="{ error: user_name_error }"
              type="text"
              placeholder="请输入账号名称"
              v-model="user_name"
            />
            <p v-if="user_name_error">{{ user_name_error_txt }}</p>
          </div>
        </div>
        <div class="dc-btn">
          <a href="javascript:;" @click="cancleFn">取消</a>
          <a href="javascript:;" @click="sureFn">确定</a>
        </div>
      </div>
    </DialogBox>
  </div>
</template>
<script>
import { update_user_name } from "@/api/index";
import { get_user_info } from '@/api/common'
import PinganAna from "pingansec-vue-ana";
export default {
  data() {
    return {
      tabs: [
        {
          img: require("@/assets/img/index/list_icon_01.png"),
          name: "广告搜索",
          router: "/box/search",
        },
        {
          img: require("@/assets/img/index/list_icon_02.png"),
          name: "推广监测",
          router: "/box/dynamic",
        },
        {
          img: require("@/assets/img/index/list_icon_03.png"),
          name: "我的收藏",
          router: "/box/collection",
        },
        {
          img: require("@/assets/img/index/list_icon_04.png"),
          name: "我的导出",
          router: "/box/export",
        },
      ],
      dialogShow: false,
      userInfo: {},
      user_name_error: false,
      user_name_error_txt: "",
      user_name: null,
    };
  },
  created() {
    PinganAna.fire(3101)
    get_user_info().then((res) => {
      this.userInfo = res.data.data;
      this.user_name = this.userInfo.user_name;
    });
  },
  methods: {
    dialogShowFn() {
      this.dialogShow = true;
    },
    closeFn() {
      this.dialogShow = false;
    },
    sureFn() {
      if (!this.user_name) {
        this.user_name_error = true;
        this.user_name_error_txt = "请输入账号名称";
      } else if (this.$C.getUnicodeLength(this.user_name) > 20) {
        this.user_name_error = true;
        this.user_name_error_txt = "账号名称过长，请输入4~20个字符";
      } else if (this.$C.getUnicodeLength(this.user_name) < 4) {
        this.user_name_error = true;
        this.user_name_error_txt = "账号名称过短，请输入4~20个字符";
      } else if (this.userInfo.user_name == this.user_name) {
        this.user_name_error = true;
        this.user_name_error_txt = "已存在的用户名---文案暂定";
      } else {
        this.user_name_error = false;
        update_user_name({
          user_name: this.user_name,
        }).then((res) => {
          let { result_code, message, data } = res.data;
          if (result_code == 0) {
            this.closeFn();
            this.userInfo.user_name = this.user_name;
            this.alert("账号名称设置成功", "success");
          } else {
            this.alert(message, "error");
          }
        });
      }
    },
    cancleFn() {
      this.closeFn();
    },
    // message提示框稍微包一下
    alert(txt, type) {
      this.$message.closeAll();
      this.$message({
        message: txt,
        type: type,
      });
    },
  },
};
</script>
<style scoped lang="scss" src="./index.scss"></style>
